<template>
	<div>
		<van-overlay
			:show="isShow"
		>
			<div class="wrapper">
				<van-loading size="36px" color="#fff" vertical>
					<span style="color:#fff; font-size: 18px">{{ title || '数据加载中，请稍候···' }}</span>
				</van-loading>
			</div>
		</van-overlay>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ''
		},
		isShow: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {};
	},
	methods: {}
};
</script>

<style scoped lang="less">
.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
</style>
