<template>
	<div class="page-container">
		<div class="header" @click="tool(dian)">
			<page-header @navBack="navBack" :back="showNavBack" :pageTitle="pageTitle">
				<!--<template v-slot:default>-->
				<!--<van-icon size="20" name="question-o"/>-->
				<!--</template>-->
			</page-header>
		</div>
		<div class="page-body">
			<div class="content">
				<c-evaluate :show="false" title="服务评价"></c-evaluate>
				<NoticeBar v-if="sysMsg" left-icon="volume-o" :text="sysMsg.title + '：' + sysMsg.message"></NoticeBar>
				<NoticeBar v-if="this.belongOrganization == '14' || this.belongOrganization == '10'"
				left-icon="volume-o" :text=" '培训课程：'+ planName"></NoticeBar>
				<!-- 步骤 -->
				<van-tabs
					v-if="isSupportSubject"
					v-model="activeSubject"
					animated
					swipeable
					:color="colorMain"
					:title-active-color="colorMain"
					title-inactive-color="#666"
					line-height="5"
				>
					<van-tab
						v-for="(item, index) in subjects"
						:key="index"
						:title="item.label"
					>
						<div class="circle-wrap" v-if="belongOrganization != 10">
							<div :class="['circle',{'zero':!rate}]">
								<van-circle
									size="116"
									v-model="currentRate"
									:color="gradientColor"
									:stroke-width="80"
									:rate="rate"
									:speed="100"
								>
									<div class="circle-inner">
										<h3 class="title">
											{{index == 0 || index == 2? periodInfo.subjectOneValidTime :
											periodInfo.subjectFourValidTime }}分钟
										</h3>
										<span>累计完成学时</span>
									</div>
								</van-circle>
							</div>
						</div>
						<div v-else class="circle-wrap">
							<div class="finish" v-if="index == 0 && periodInfo.subjectOneStudyTime >= periodInfo.subjectOneTotalTime1" />
							<div class="unfinish" v-if="index == 0 && periodInfo.subjectOneStudyTime < periodInfo.subjectOneTotalTime1" />
							<div class="finish" v-if="index == 1 && periodInfo.subjectFourStudyTime >= periodInfo.subjectFourTotalTime1" />
							<div class="unfinish" v-if="index == 1 && periodInfo.subjectFourStudyTime < periodInfo.subjectFourTotalTime1" />
						</div>
					<a href="javascript:;" @click="thechangePlan" v-if="periodInfo.changePlan != null && index == 0 && periodInfo.subjectOneStudyTime >= periodInfo.subjectOneTotalTime1" style="margin:10% 0% 0px 40%;font-size:180%;font-weight: 700;text-align:center;color:#D22B2B;">切换课程</a>
					<a href="javascript:;" @click="thechangePlan" v-if="periodInfo.changePlan != null && index == 1 && periodInfo.subjectFourStudyTime >= periodInfo.subjectFourTotalTime1" style="margin:10% 0% 0px 40%;font-size:180%;font-weight: 700;text-align:center;color:#D22B2B;">切换课程</a>
						<div class="info" style="margin-top: 5%">
							<div class="info-item">
								<div class="top">
									<span class="bold">
										{{index == 0 || index == 2?
										periodInfo.subjectOneTotalTime1 == null? periodInfo.subjectOneTotalTime : periodInfo.subjectOneTotalTime1
										: periodInfo.subjectFourTotalTime1 == null? periodInfo.subjectFourTotalTime: periodInfo.subjectFourTotalTime1}}
									</span>
									<span class="s-14" v-if="belongOrganization == 10">学时</span>
									<span class="s-14" v-else>分钟</span>
								</div>
								<span class="bottom">目标学时</span>
							</div>
							<div class="info-item" v-show="showHasLearned">
								<div class="top">
									<span class="bold" >{{index == 0 || index == 2? periodInfo.subjectOneStudyTime : periodInfo.subjectFourStudyTime}}</span>
									<span class="s-14" v-if="belongOrganization == 10">学时</span>
									<span class="s-14" v-else>分钟</span>
								</div>
								<span class="bottom" v-if="belongOrganization == 10">有效学时</span>
								<span class="bottom" v-else>已学学时</span>
							</div>
							<div class="info-item c-main" v-if="false">
								<div class="top s-18">
									<span class="bold">{{index == 0 || index == 2? periodInfo.subjectOneValidTime : periodInfo.subjectFourValidTime}}</span>
									<span class="s-14">分钟</span>
								</div>
								<van-popover theme="dark" v-model="showPop0" trigger="click">
									<div class="tips">经过初审、复审、终审后的有效学时</div>
									<template #reference>
										<span class="bottom">
											有效学时
											<van-icon :color="colorMain" size="13" name="warning-o"/>
										</span>
									</template>
								</van-popover>
							</div>
						</div>
					</van-tab>
				</van-tabs>
				<!-- 不支持科一科四 -->
				<div v-else class="no-subject">
					<div class="circle-wrap">
						<div :class="['circle',{'zero':!rate}]">
							<van-circle
								size="116"
								v-model="currentRate"
								:color="gradientColor"
								:stroke-width="80"
								:rate="rate"
								:speed="100"
							>
								<div class="circle-inner">
									<h3 class="title">
										{{ timeUnit ? (periodInfo.otherTypeValidTime || '0') : otherTypeValidTimeSP }}{{ timeUnit ? '分钟' : '学时' }}
									</h3>
									<span>累计完成学时</span>
								</div>
							</van-circle>
						</div>
					</div>
					<div class="info">
						<div class="info-item">
							<div class="top">
								<span class="bold">{{ timeUnit ? periodInfo.otherTypeTotalTime1 == null?
									periodInfo.otherTypeTotalTime : periodInfo.otherTypeTotalTime1 : otherTypeTotalTimeSP }}</span>
								<span class="s-14">{{ timeUnit ? '分钟' : '学时' }}</span>
							</div>
							<span class="bottom">目标学时</span>
						</div>
						<div class="info-item" v-show="showHasLearned">
							<div class="top">
								<span class="bold">{{ timeUnit ? periodInfo.otherTypeStudyTime : otherTypeStudyTimeSP }}</span>
								<span class="s-14">{{ timeUnit ? '分钟' : '%' }}</span>
							</div>
							<span class="bottom">已学学时</span>
						</div>
						<div class="info-item c-main" v-if="false">
							<div class="top s-18">
								<span class="bold">{{ timeUnit ? periodInfo.otherTypeValidTime : otherTypeValidTimeSP }}</span>
								<span class="s-14">{{ timeUnit ? '分钟' : '学时' }}</span>
							</div>
							<van-popover theme="dark" v-model="showPop0" trigger="click">
								<div class="tips">经过初审、复审、终审后的有效学时</div>
								<template #reference>
									<span class="bottom">
										已学学时
										<van-icon :color="colorMain" size="13" name="warning-o"/>
									</span>
								</template>
							</van-popover>
						</div>
					</div>
				</div>
				<div class="btn-wrap" style="margin-top:20px;">
					<van-button
						class="btn"
						type="primary"
						block
						@click="start"
					>
						开始学习
					</van-button>
					<div v-if="isReset||resetFlag
					||(!isSupportSubject&&(periodInfo.otherTypeStudyTime>=periodInfo.otherTypeTotalTime&&periodInfo.otherTypeValidTime!=0))
           	 		||(activeSubject==0&&(periodInfo.subjectOneStudyTime>=periodInfo.subjectOneTotalTime&&periodInfo.subjectOneValidTime!=0))
					||(activeSubject==2&&(periodInfo.subjectOneStudyTime>=periodInfo.subjectOneTotalTime&&periodInfo.subjectOneValidTime!=0))
            		||(activeSubject==1&&(periodInfo.subjectFourStudyTime>=periodInfo.subjectFourTotalTime&&periodInfo.subjectFourValidTime!=0))
					||(activeSubject==3&&(periodInfo.subjectFourStudyTime>=periodInfo.subjectFourTotalTime&&periodInfo.subjectFourValidTime!=0))"
						 class="a-btn"
					>
						<a v-if="otherReset" href="javascript:;">
							<img src="../assets/icon/icon_right1.png" height="12px" width="20px" style="margin: 0 5px 0 0;" alt="">
							<span style="margin-right:2px; font-size:110%" class="imp-check" @click="resetPeriod">学时不够，请点这里</span>
							<van-popover theme="dark" v-model="showPop" trigger="click">
								<div class="tips">
									已学学时已满时，但有效学时不足时，可继续累加学时
								</div>
								<template #reference>
									<van-icon :color="colorMain" size="13" name="warning-o"/>
								</template>
							</van-popover>
						</a>
						<a href="javascript:;" @click="navToTest" v-if="isTested" class="imp-check">{{testTitle}}</a>
						<a href="javascript:;" @click="check" v-if="isQualified" class="imp-check">合格证明</a>
					</div>
					<div class="a-btn" v-if="showPeriodViewBtn">
						<span style="color:#ee0a24;" @click="periodView"><u>&gt;&gt;&gt; 查询学时审核结果 &lt;&lt;&lt;</u></span>
					</div>
					<div class="a-btn" v-if="isTestedYN">
						<a href="javascript:;" @click="navToTest" class="imp-check">模拟练习题·{{this.activeSubject == 0 || this.activeSubject == 2 ? '(科目一)' : '(科目四)'}}</a>
					</div>
					<div v-if="juHuiSignUp" style="margin-top:2%; margin:0px 10% 0px 10%;font-size:15px;font-weight: 700;text-align:center;color:#ee0a24;">
						<span @click="juHuiReSignUp"><u>温馨提示：您已学习完毕，请点击此处重新报名学习</u></span>
					</div>
					<div v-if="lndyIsFinished" style="margin:0px 10% 0px 10%;font-size:15px;font-weight: 700;text-align:center;color:#ee0a24;">
						<hr style="height:1px;border:none;border-top:1px solid #A9A9A9;" />
						<img src="../assets/icon/icon_down.png" height="25px" style="width:14px;margin: 0 5px 0 0;" alt=""><br>
						<span @click="dongYuReSignUp"><u>再次报名学习</u></span>
					</div>
					<div v-if="isFinished" style="margin:0px 10% 0px 10%;font-size:15px;font-weight: 700;text-align:center;color:#ee0a24;">
						<hr style="height:1px;border:none;border-top:1px solid #A9A9A9;" />
						<img src="../assets/icon/icon_down.png" height="25px" style="width:14px;margin: 0 5px 0 0;" alt=""><br>
						<span @click="reSignUp"><u>再次报名学习</u></span>
					</div>
					<div v-if="showCoach" style="margin:0px 10% 0px 10%;font-size:15px;font-weight: 700;text-align:center;color:#ee0a24;">
						<hr style="height:1px;border:none;border-top:1px solid #A9A9A9;" />
						<img src="../assets/icon/icon_down.png" height="25px" style="width:14px;margin: 0 5px 0 0;" alt=""><br>
						<span @click="updateCoachInfo"><u>获取新课程</u></span>
					</div>
					<div v-if="istips" style="margin:0px 10% 0px 10%;font-size:10px;font-weight: 700;text-align:center;color:#ee0a24;">
						<hr style="height:1px;border:none;border-top:1px solid #A9A9A9;" />
						<img src="../assets/icon/icon_down.png" height="25px" style="width:14px;margin: 0 5px 0 0;" alt="">
						<span><br>温馨提示：合格证自行打印或联系客服<br>客服电话：15690942336（微信同号)</span>
					</div>
				</div>

				<!-- 调起粤学车小程序测试按钮 -->
				<div style="margin-top: 20px; text-align: center" v-if="dian >= 5">
					<van-button
						class="btn"
						type="primary"
						@click="openMiniAppTest"
					>
						模拟粤学车小程序回调
					</van-button>
				</div>
			</div>

			<div class="record" style="margin-bottom: 15px" v-if="showStudentInfo">
				<div class="title s-17 line">学员信息</div>
				<div class="stud-info">
					<div class="section-top">
						<div class="left">
							<div class="section-content">
								<div class="list-item">
									<span class="name">姓名：</span>
									<span class="value">{{periodInfo.studentName}}</span>
								</div>
								<div class="list-item">
									<span class="name">证件：</span>
									<span class="value">{{periodInfo.certNum}}</span>
								</div>
								<div class="list-item" v-if="this.belongOrganization != 38 && this.belongOrganization != 41">
									<span class="name">{{periodInfo.enterpriseType}}：</span>
									<span class="value">{{periodInfo.enterpriseName}}</span>
								</div>
								<div class="list-item" v-if="this.belongOrganization == 41">
									<span class="name">车型：</span>
									<span class="value">{{periodInfo.trainType}}</span>
								</div>
								<div class="list-item" v-if="this.belongOrganization == 41">
									<span class="name">科一已学(有效学时/总学时)：</span>
									<span class="value">{{periodInfo.sub1Validtime}}/{{periodInfo.totalTimePart1}}</span>
									<!-- <span class="name">总学时：</span>
									<span class="value"></span> -->
								</div>
								<div class="list-item" v-if="this.belongOrganization == 41">
									<span class="name">科四已学(有效学时/总学时)：</span>
									<span class="value">{{periodInfo.sub4Validtime}}/{{periodInfo.totalTimePart4}}</span>
								</div>
								<div class="list-item" v-if="this.belongOrganization == 51">
									<span class="name">科一已学(有效学时)：</span>
									<span class="value">{{periodInfo.sub1Validtime}}</span>
									<!-- <span class="name">总学时：</span>
									<span class="value"></span> -->
								</div>
								<div class="list-item" v-if="this.belongOrganization == 51">
									<span class="name">科四已学(有效学时)：</span>
									<span class="value">{{periodInfo.sub4Validtime}}</span>
								</div>
								<div class="list-item" v-if="this.belongOrganization == 55 && periodInfo.sub1Validtime != null">
									<span class="name">科一已学(有效学时)：</span>
									<span class="value">{{periodInfo.sub1Validtime}}</span>
									<!-- <span class="name">总学时：</span>
									<span class="value"></span> -->
								</div>
								<div class="list-item" v-if="this.belongOrganization == 55 && periodInfo.sub4Validtime != null" >
									<span class="name">科四已学(有效学时)：</span>
									<span class="value">{{periodInfo.sub4Validtime}}</span>
								</div>
							</div>
						</div>
						<div class="right">
							<img :src="periodInfo.studentPic" alt="avatar" />
						</div>
					</div>
					<div class="cx-picker" v-if="periodInfo.changeCx">
						<van-field
							readonly
							clickable
							name="trainType"
							v-model="periodInfo.trainType"
							label="车型："
							placeholder="点击选择车型"
							@click="showPickerCx = true"
						>
							<template #button>
								更改车型 &gt;
							</template>
						</van-field>
						<van-popup v-model="showPickerCx" position="bottom">
							<van-picker
								show-toolbar
								:columns="columnsCx"
								@confirm="onConfirmPickerCx"
								@cancel="showPickerCx = false"
								value-key="text"
								:default-index="0"
								confirm-button-text="更改车型"
							/>
						</van-popup>
					</div>
				</div>
			</div>

			<div class="record" v-if="showStudyLog">
				<div class="title s-17 line">教学日志</div>
				<van-list
					v-model="loading"
					:finished="finished"
					finished-text="没有更多了"
					@load="getSignRecord"
				>
					<!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
					<div class="table">
						<div class="table-list hd">
							<span class="item">时间</span>
							<span class="item">学时</span>
							<span class="item">状态</span>
						</div>
						<div
							v-for="(item, index) of studyList"
							:key="index"
							class="table-list"
						>
							<span class="item">{{ item.signInTime }}</span>
							<span class="item">{{ item.totalPeriod }}分钟</span>
							<span v-if ="ifshowLogInfo" style="text-decoration: underline;cursor:pointer;" :class="[
								'item',{
				                    grey: item.signStatus == 3,
				                    grey: item.signStatus == 4,
				                    grey: item.signStatus == 99,
				                    orange: item.signStatus == 1,
				                    red: item.signStatus == 0,
                                },
                            ]" @click="showCurrentLogInfo(item.signId)">{{ periodStatusMap[item.periodStatus] }}</span>

							<span v-else :class="[
								'item',{
				                    grey: item.signStatus == 3,
				                    grey: item.signStatus == 4,
				                    grey: item.signStatus == 99,
				                    orange: item.signStatus == 1,
				                    red: item.signStatus == 0,
                                },
                            ]">{{ periodStatusMap[item.periodStatus] }}</span>
						</div>
					</div>
				</van-list>
			</div>
		</div>

		<!-- 隐藏人脸照片 -->
		<canvas id="canvas" width="480" height="320"
				style="position:absolute;top:-999px;left:-9999px;opacity:0;"></canvas>

		<van-popup round v-model="showRate">
			<div class="popup-content">
				<div class="title">
					<span>服务评价</span>
				</div>
				<div class="content">
					<div class="list">
						<span class="title s-14 line">服务态度与意识</span>
						<van-rate
							v-model="serviceRate.serviceAwareness"
							icon="star"
							:color="colorMain"
							void-icon="star-o"
						/>
					</div>
					<div class="list">
						<span class="title s-14 line">服务能力</span>
						<van-rate
							v-model="serviceRate.serviceAbility"
							icon="star"
							:color="colorMain"
							void-icon="star-o"
						/>
					</div>
					<div class="list">
						<span class="title s-14 line">服务效率</span>
						<van-rate
							v-model="serviceRate.serviceEfficiency"
							icon="star"
							:color="colorMain"
							void-icon="star-o"
						/>
					</div>
				</div>
				<div class="btn-wrap">
					<van-button @click="cancle" class="btn default" type="primary">取消</van-button>
					<van-button @click="doServiceComment" :loading="serviceRateLoading" class="btn" type="primary">确定</van-button>
				</div>
			</div>
		</van-popup>

		<van-popup round v-model="showReset">
			<div class="popup-reset">
				<div class="content">
					确定需继续累加学时吗？
				</div>
				<div class="btn-wrap">
					<van-button @click="cancle" class="btn default" type="primary">点错了</van-button>
					<van-button @click="confirmReset" class="btn" type="primary">确定</van-button>
				</div>
			</div>
		</van-popup>

		<van-popup round v-model="showRecovery">
			<div class="popup-reset">
				<div class="content">
					<div>尊敬的用户：</div>
					<div style="text-indent: 2em">您好！</div>
					<div style="text-indent: 2em">距离您最近一次签到学习已经过去很长时间了，为保证您学习数据的安全性，我们已将您的学习记录存入数据保险柜中。如果您要继续学习或查询之前的学习记录，请点击 [还原] 按钮。</div>
					<div style="text-align: right">谢谢~</div>
				</div>
				<div class="btn-wrap">
					<van-button @click="cancelRecovery" class="btn default" type="primary">取消</van-button>
					<van-button @click="confirmRecovery" class="btn" type="primary" :loading="loadingRecovery">还原</van-button>
				</div>
			</div>
		</van-popup>

		<van-popup v-model="showQrcode" position="top" :style="{ height: '30%' }">
			<div class="share-tips">
				<img :src="qrcodeImgUrl" />
				<div><span>扫描二维码可查看合格证明摘要或下载合格证明。</span></div>
				<div><a href="javascript:;" @click="showCertificatePage">点击这里查看合格证明摘要&gt;&gt;&gt;</a></div>
			</div>
		</van-popup>

		<div class="logout-sp" :style="logoutDisplay">
			<a href="javascript:;" @click="logout">退出学习</a>
		</div>
		<div class="change-xm" v-if="showChangePlanLink && this.belongOrganization != '38'">
			<a href="javascript:;" @click="changePlan">切换课程</a>
		</div>
		<!-- 全屏遮罩loading组件 -->
		<xm-loading :is-show="xmLoadingShow"/>
	</div>
</template>

<script>
import Vconsole from 'vconsole'
import PageHeader from "../components/page-header.vue";
import XmLoading from "../components/loading.vue";
import {
	changeTrainType,
	checkCoachStatus,
	checkStudentStudyStatus,
	doServiceComment,
	getNewCertificate,
	getPeriodInfo,
	getSignRecord,
	initCzStudent,
	isFinish,
	juHuiIsFinish,
	recoveryCheckStatus,
	recoveryProc,
	resetPeriodInfo,
	reSignUp,
	showLogInfo,
	signIn,
	sysMessage,
	updateCoachInfo,
	dongYuIsFinish,
	dongYuReSignUp,
	wxConfig,
	saveMiniProgramCallBack, checkMiniProgramAuthData
} from '../api/user';
import Vue from 'vue';
import { Dialog, NoticeBar, Toast } from 'vant';
import { getCityCode, getEnterpriseId } from '@/utils/cookie';

export default {
	data() {
		return {
			xmLoadingShow: true,
			logoutDisplay: 'display:none',
			showStudentInfo: false,
			showPickerCx: false,
			loadingRecovery: false,
			showRecovery: false,
			dian: 0,
			timeUnit: true,
			showChangePlanLink: false,
			showHasLearned: true,
			showStudyLog: true,
			showLogout: false,
			qrcodeImgUrl: '',
			showQrcode: false,
			isReset: false,
			otherReset: true,
			resetFlag:false,
			isQualified: false,
			istips: false,
			isTested: false,
			isTestedYN: false,
			lndyIsFinished: false,
			isFinished:false,
			juHuiSignUp:false,
			testTitle: '进入考试',
			sysMsg: null,
			colorMain: "#ff8506",
			showRate: false,
			showPop0: false,
			showPop: false,
			loading: false,
			finished: false, // 签到列表
			showReset: false, // 重置学时
			isSupportSubject: true, // 是否支持科目一、四
			pageSize: 10,
			subjectFourHours: 0.00,
			subjectOneHours: 0.00,
			ifshowLogInfo: false,
			duration: "0",
			deductionduration:"0",
			deductionreson:"",
			currentPage: 1,
			activeSubject: 0,
			planName:'',
			showCoach:false,
			testStudyFlag:false,
			subjects: [
				{
					label: "科目一",
					value: 1,
				},
				{
					label: "科目四",
					value: 4,
				},
			],
			periodInfo: {},
			// 0待签退1待上传2已上传3上传失败4已取消99生效
			periodStatusMap: {
				0: "待签退",
				1: "待上传",
				2: "已上传",
				3: "上传失败",
				4: "已取消",
				5: "学时重置",
				99: "生效",
			},
			// rate: 100,
			currentRate: 0,
			gradientColor: {
				"0%": "#FFE1C1",
				"100%": "#FF8506",
			},
			statusMap: {
				0: "待签退",
				1: "待上传",
				2: "已上传",
				3: "上传失败",
				4: "已取消",
				5: "学时重置",
				99: "生效",
			},
			studyList: [],
			serviceRateLoading: false,
			serviceRate: {
				studentId: '',
				serviceAwareness: 5,
				serviceAbility: 5,
				serviceEfficiency: 5,
			},
			studyDone: 0, // 当前是否学习完成
			signPic: '', // 是否带有经过人脸后的照片url
			belongOrganization: '',
			columnsCx: [
				{text: 'C1', value: 'C1'},
				{text: 'C2', value: 'C2'},
				{text: 'C3', value: 'C3'},
			],
			studentPayStatus: '0',
			showNavBack: false,
			showPeriodViewBtn: false,
			wxMiniProgramCallBack: false,
		}
	},
	computed: {
		pageTitle() {
			const appno = sessionStorage.getItem('appno');
			if (appno === 'APP02') return '继续教育培训'
			if (appno === 'APP03') return '企业安全培训'
			if (appno === 'APP04') return '岗前教育培训'
			if (appno === 'APP05') return '计分教育培训'
			if (appno === 'APP06') return '两类人员培训'
			if (appno === 'APP09') return '从业资格证培训'
			if (appno === 'APP10') return '公务用车安全培训'
			return '驾培理论培训'
		},
		rate() {
			if (this.periodInfo.otherTypeValidTime || this.periodInfo.subjectOneValidTime || this.periodInfo.subjectFourValidTime) {
				if (!this.isSupportSubject) {
					if (!this.periodInfo.otherTypeTotalTime) return 0;
					return (this.periodInfo.otherTypeValidTime / this.periodInfo.otherTypeTotalTime) * 100;
				} else {
					if (this.activeSubject == 0 || this.activeSubject == 2) {
						return (this.periodInfo.subjectOneValidTime / this.periodInfo.subjectOneTotalTime) * 100;
					} else {
						return (this.periodInfo.subjectFourValidTime / this.periodInfo.subjectFourTotalTime) * 100;
					}
				}
			}
			// return this.currentRate.toFixed(0) + '%';
			return 0;
		},
		userid() {
			return this.$store.state.user.userid
		}
	},
	watch: {
		activeSubject: function (newVal) {
			this.$store.commit('cache/set_subject', {key: 'curSubjectIndex', value: newVal})
			this.currentPage = 1;
			this.studyList = [];
			if(newVal == 2 || newVal == 0){
				this.isReset = this.periodInfo.ifReset1;
				this.resetFlag = false;
			}else{
				this.isReset = this.periodInfo.ifReset4;
				this.resetFlag = false;
			}
			this.getSignRecord(true);
		}
	},
	components: {
		PageHeader,
		NoticeBar,
		XmLoading
	},
	created() {
		sessionStorage.removeItem("loginSteam")
		sessionStorage.setItem("loginSteam",window.location.href)
		sessionStorage.removeItem("organizationNo")
		sessionStorage.setItem("organizationNo",this.$route.query.organizationNo)
		this.activeSubject = this.$store.state.cache.subject.curSubjectIndex
		this.checkSingleLogin()
		if (this.$route.query.state !== 'single') {
			const cityCode = getCityCode();
			// 辽宁东宇
			if (cityCode.substring(0, 2) === '21') {
				this.dongYuIsFinish()
			}
			this.isFinish()
			this.juHuiFinish()
			this.coachIsFinish()
		}
	},
	mounted() {
		// 广州交信投APP回调
		window['onWXMiniProgram'] = (extraData) => {
			console.log('小程序回调', extraData.substring(0, 10));
			// 保存小程序授权信息
			saveMiniProgramCallBack(JSON.parse(extraData))
				.then(() => {
					this.wxMiniProgramCallBack = true;
					//this.start();
				})
				.catch(() => {
					this.$utils.dialog.alert('保存授权信息时出错，请联系系统管理员！');
				});
		},
		wxConfig().then(res => {
			console.log("wxConfig",res)
			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: res.appId, // 必填，公众号的唯一标识
				timestamp: res.timestamp, // 必填，生成签名的时间戳
				nonceStr: res.nonceStr, // 必填，生成签名的随机串
				signature: res.signature,// 必填，签名
				jsApiList:['chooseImage', 'previewImage'], //需要使用的JS接口列表,这里是分享到朋友圈和朋友的
				openTagList:['wx-open-launch-weapp']// 必填，需要使用的JS接口列表
			})
			wx.ready(() => {
				console.log('微信config执行')
			})
			wx.error(err => {
				console.log('微信config出错 ===', err)
				this.$utils.dialog.alert(`微信config失败：${err.errMsg}`)
			})
		})
		// sessionStorage.removeItem('loginInfor')
		const appno = sessionStorage.getItem('appno');
		switch (appno) {
			case 'APP02':
				this.colorMain = '#2db1ac';
				this.gradientColor = {
					"0%": "#96cdfa",
					"100%": "#2db1ac",
				}
				this.isSupportSubject = false;
				break;
			case 'APP03':
				this.colorMain = '#1175fe';
				this.gradientColor = {
					"0%": "#b1ffee",
					"100%": "#1175fe",
				}
				this.isSupportSubject = false;
				this.showChangePlanLink = true;
				break;
			case 'APP04':
				this.colorMain = '#ff8506';
				this.gradientColor = {
					"0%": "#FFE1C1",
					"100%": "#FF8506",
				}
				this.isSupportSubject = false;
				break;
			case 'APP05':
				this.colorMain = '#ff8506';
				this.gradientColor = {
					"0%": "#FFE1C1",
					"100%": "#FF8506",
				}
				this.isSupportSubject = false;
				break;
			case 'APP06':
				this.colorMain = '#ff8506';
				this.gradientColor = {
					"0%": "#FFE1C1",
					"100%": "#FF8506",
				}
				this.isSupportSubject = false;
				break;
			case 'APP08':
				this.colorMain = '#ff8506';
				this.gradientColor = {
					"0%": "#FFE1C1",
					"100%": "#FF8506",
				}
				this.isSupportSubject = false;
				break;
			case 'APP09':
				this.colorMain = '#ff8506';
				this.gradientColor = {
					"0%": "#FFE1C1",
					"100%": "#FF8506",
				}
				this.isSupportSubject = false;
				break;
			case 'APP10':
				this.colorMain = '#ff8506';
				this.gradientColor = {
					"0%": "#FFE1C1",
					"100%": "#FF8506",
				}
				this.isSupportSubject = false;
				break;
			default:
				this.colorMain = '#ff8506';
				this.gradientColor = {
					"0%": "#FFE1C1",
					"100%": "#FF8506",
				}
				this.isSupportSubject = true;
				break;
		}
		// if (this.$route.query.imgurl) {
		// 	this.signPic = this.$route.query.imgurl;
		// }
		this.signPic = this.$store.state.cache.face.signInPhoto
		if (this.$route.query.state !== 'single') {
			this.getPeriodInfo();
			this.checkRecovery();
		}
		// this.getSignRecord();
	},
	beforeDestory() {
		sessionStorage.removeItem('appno');
		this.$store.dispatch('clearAppno');
	},
	methods: {
		openMiniAppTest() {
			this.wxMiniProgramCallBack = true;
		},
		sucFun(msg) { console.log(msg) },
		errFun(msg) { console.log(msg) },
		onConfirmPickerCx(value) {
			this.showPickerCx = false;
			changeTrainType({
				"studentId": this.$store.state.user.userid,
				"trainType": value.value
			}).then(() => {
				this.$utils.dialog.alert('更改培训车型成功！');
				this.periodInfo.trainType = value.value;
			})
		},
		coachIsFinish() {
			checkCoachStatus(this.$store.state.user.userid).then(res => {
				if(res.status == 9){
					this.showCoach = true;
					this.studentNo = res.studentNo;
				}
			});
		},
		//辽宁东宇继续教育学员是否学完
		dongYuIsFinish() {
			dongYuIsFinish({
				"basePkId": this.$store.state.user.userid
			}).then((res) => {
				if (res && res.status === 9) {
					this.lndyIsFinished = true;
				}
			})
		},
		//山东继续教育学员是否学完
		isFinish() {
			isFinish({
				"basePkId": this.$store.state.user.userid
			}).then((res) => {
				this.isFinished = res.flag;
			})
		},
		//检测内蒙学员是否学完
		juHuiFinish() {
			juHuiIsFinish({
				"studentNo": this.$store.state.user.userid
			}).then((res) => {
				if (res && res.status === 9) {
					this.juHuiSignUp = true;
				}
			})
		},
		// 验证是否单点登录
		checkSingleLogin() {
			// http://localhost:8080/#/train?state=single&certNum=140421199406208035&city=北京市&cityCode=110100&mobile=13511013720&studentName=苗越超
			// https://h5.xunmeizaixian.com/#/train?state=single&certNum=140421199406208035&city=%E5%8C%97%E4%BA%AC%E5%B8%82&cityCode=110100&mobile=13511013720&studentName=%E8%8B%97%E8%B6%8A%E8%B6%85
			if (this.$route.query.state === 'single') {
				if (this.$route.query.organizationNo) {
					const {appNo, organizationNo, token, subject, idcard, name, studyType,trainType} = this.$route.query
					const param = {appNo, organizationNo, token, subject, idcard, name, studyType,trainType}
					this.$store.commit('cache/set_single', param)
					this.$store.dispatch('SingleLogin', param).then(() => {
						console.log(`===========[SingleLogin] 单点 登录 成功!========`);
						if (this.$route.query.subject) {
							if (this.$route.query.subject == 1) {
								this.activeSubject = 0;
							} else if (this.$route.query.subject == 4) {
								this.activeSubject = 1;
							}
							this.isSupportSubject = true;
						}
						sessionStorage.setItem("studyType", this.$route.query.studyType);
						if (param.organizationNo === 'C23HF93JSX8') {
							sessionStorage.setItem("anhuihefei", '1');
							localStorage.setItem('loginInfor_' + param.appNo, JSON.stringify({
								idcard: param.idcard,
								studentName: param.name
							}));
						}
						this.getPeriodInfo();
						this.getSignRecord(true);
						this.getSysMessage();
						this.checkRecovery();
					})
				} else {
					const {state, runScreen, certNum, city, cityCode, mobile, studentName} = this.$route.query
					const param = {state, runScreen, certNum, city, cityCode, mobile, studentName}
					for (let i = 0; i < Object.keys(this.$route.query).length; i++) {
						const item = Object.keys(this.$route.query)[i]
						if (item.startsWith('o-')) param[item.slice(2)] = this.$route.query[item]
					}
					this.$store.commit('cache/set_single', param)
					this.$store.dispatch('Login', param).then(() => {
						console.log(`===========[Login] 单点 登录 成功!========`);
						this.getPeriodInfo();
						this.getSignRecord(true);
						this.getSysMessage();
						this.checkRecovery();
					})
				}
			} else {
				this.getSysMessage()
			}
		},
		// 获取系统消息
		getSysMessage() {
			sysMessage(this.$store.state.token, this.$store.state.cityCode).then((data) => {
				if (data.length > 0) this.sysMsg = data[0]
			})
		},
		getBrowserInfo() {
			var agent = navigator.userAgent.toLowerCase();
			var arr = [];
			var system = agent.split(' ')[1].split(' ')[0].split('(')[1];
			arr.push(system);
			var regStr_edge = /edge\/[\d.]+/gi;
			var regStr_ie = /trident\/[\d.]+/gi;
			var regStr_ff = /firefox\/[\d.]+/gi;
			var regStr_chrome = /chrome\/[\d.]+/gi;
			var regStr_saf = /safari\/[\d.]+/gi;
			var regStr_opera = /opr\/[\d.]+/gi;
			//IE
			if (agent.indexOf("trident") > 0) {
				arr.push(agent.match(regStr_ie)[0].split('/')[0]);
				arr.push(agent.match(regStr_ie)[0].split('/')[1]);
				return arr;
			}
			//Edge
			if (agent.indexOf('edge') > 0) {
				arr.push(agent.match(regStr_edge)[0].split('/')[0]);
				arr.push(agent.match(regStr_edge)[0].split('/')[1]);
				return arr;
			}
			//firefox
			if (agent.indexOf("firefox") > 0) {
				arr.push(agent.match(regStr_ff)[0].split('/')[0]);
				arr.push(agent.match(regStr_ff)[0].split('/')[1]);
				return arr;
			}
			//Opera
			if (agent.indexOf("opr") > 0) {
				arr.push(agent.match(regStr_opera)[0].split('/')[0]);
				arr.push(agent.match(regStr_opera)[0].split('/')[1]);
				return arr;
			}
			//Safari
			if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
				arr.push(agent.match(regStr_saf)[0].split('/')[0]);
				arr.push(agent.match(regStr_saf)[0].split('/')[1]);
				return arr;
			}
			//Chrome
			if (agent.indexOf("chrome") > 0) {
				arr.push(agent.match(regStr_chrome)[0].split('/')[0]);
				arr.push(agent.match(regStr_chrome)[0].split('/')[1]);
				return arr;
			} else {
				arr.push('请更换主流浏览器，例如chrome,firefox,opera,safari,IE,Edge!')
				return arr;
			}
		},
		//访问用户媒体设备的兼容方法
		getUserMedia(constraints, success, error) {
			if (navigator.mediaDevices.getUserMedia) {
				//最新的标准API
				navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error);
			} else if (navigator.webkitGetUserMedia) {
				//webkit核心浏览器
				navigator.webkitGetUserMedia(constraints, success, error)
			} else if (navigator.mozGetUserMedia) {
				//firfox浏览器
				navigator.mozGetUserMedia(constraints, success, error);
			} else if (navigator.getUserMedia) {
				//旧版API
				navigator.getUserMedia(constraints, success, error);
			}
		},
		success() {
		},
		error(error) {
			console.log(`访问用户媒体设备失败${error.name}, ${error.message}`);
		},
		checkStudyDone() {
			if (!this.isSupportSubject) {
				return (this.periodInfo.otherTypeUnResetTime >= this.periodInfo.otherTypeTotalTime);
			} else {
				if (this.activeSubject == 0 || this.activeSubject == 2) {
					return (this.periodInfo.subjectOneUnResetTime >= this.periodInfo.subjectOneTotalTime);
				} else {
					return (this.periodInfo.subjectFourUnResetTime >= this.periodInfo.subjectFourTotalTime);
				}
			}
		},
		async isExistStudy() {
			const res = await checkStudentStudyStatus(this.$store.state.userid)
			if (res && res.studyStatus != 0) {
				this.$utils.dialog.alert(res.message)
				return false
			}
			return true
		},
		checkSignOut(type) {
			this.$api.getNoSignOut({
				studentId: this.userid,
				belongCategory: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4,
			}).then(res => {
				// subject: query.belongCategory,
				// courseid: query.courseid,
				// signId: res.basePkId
				if (res.code === 0) {
					if(this.activeSubject == 2 || this.activeSubject == 3){
						if (res.data.SignInRecord) {
							this.$utils.dialog.alert('检测到您有正在进行的学习', () => {
								this.$router.push({
									path: '/question',
									query: {
										subject: res.data.SignInRecord.belongCategory,
										courseid: res.data.SignInRecord.courseId,
										signId: res.data.SignInRecord.basePkId,
										idCard: this.periodInfo.idCard,
										continuing: 1,
									}
								})
							}, '继续学习')
							return
						}
					} else {
						if (res.data.SignInRecord) {
							if(this.periodInfo.mediaPlayer == 2){
								this.$utils.dialog.alert('检测到您有正在进行的学习', () => {
									this.$router.push({
										path: '/study1',
										query: {
											subject: res.data.SignInRecord.belongCategory,
											courseid: res.data.SignInRecord.courseId,
											signId: res.data.SignInRecord.basePkId
										}
									})
								}, '继续学习')
							}else{
								this.$utils.dialog.alert('检测到您有正在进行的学习', () => {
									this.$router.push({
										path: '/study',
										query: {
											subject: res.data.SignInRecord.belongCategory,
											courseid: res.data.SignInRecord.courseId,
											signId: res.data.SignInRecord.basePkId
										}
									})
								}, '继续学习')
							}
							return
						}
					}
					if (this.periodInfo.blendedLearningFlag == 0) {
						const subject = !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4
						if (subject === 4) {
							if (this.periodInfo.subjectOneStudyTime < this.periodInfo.subjectOneTotalTime) {
								this.$utils.dialog.alert('科目一未学完，请按顺序学习')
								return
							}
						}
					}
					this.$router.push({
						path: '/face',
						query: {
							belongCategory: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4,
							unitType: type,
							courseid: !this.isSupportSubject
								? this.periodInfo.otherTypeCourseId : this.activeSubject == 0 || this.activeSubject == 2
									? this.periodInfo.subjectOneCourseId : this.periodInfo.subjectFourCourseId,
							activeSubject:this.activeSubject,
							idCard: this.periodInfo.idCard,
							mediaPlayer: this.periodInfo.mediaPlayer,
						}
					});
					// if (this.periodInfo.faceRecognitionType == 0) { // 等于 0 则不需要验证人脸
					// 	this.$router.push({
					// 		path: '/study',
					// 		query: {
					// 			subject: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4,
					// 			courseid: !this.isSupportSubject
					// 				? this.periodInfo.otherTypeCourseId : this.activeSubject == 0 || this.activeSubject == 2
					// 					? this.periodInfo.subjectOneCourseId : this.periodInfo.subjectFourCourseId,
					// 			signId: null,
					// 			isFace: 'noFace'
					// 		}
					// 	})
					// } else {
					// }
				}
				console.log(res)
			})
		},
		start() {
			if (location.host.startsWith('shuangpu.')) {
				this.$utils.dialog.alert("抱歉，学习流程暂停开放，如有疑问请拨打 15635372874 咨询！");
				return false;
			}
			if (this.belongOrganization === '') {
				this.$utils.dialog.alert("请稍候点击，正在加载学习数据！");
				return false;
			}
			if (this.belongOrganization == '45' && this.studentPayStatus == '0') {
				// 安徽合肥未支付先微信支付
				localStorage.setItem('payUserId', this.userid);
				this.$router.push({ path: "/auth" });
				return false;
			}
			const cityCode = getCityCode();
			// APP学习
			let appStudy = 0;
			if (this.belongOrganization == '24' || this.belongOrganization == '41' || this.belongOrganization == '45' || this.belongOrganization == '57') {
				appStudy = 1;
			}
			// 云南和常州禁止PC端浏览器学习
			// if (cityCode.substring(0, 2) === '53' || cityCode === '320400') {
			if (this.isPcWeixinBrowser() && appStudy === 0 && this.dian <= 5 && sessionStorage.getItem("studyType") != 1 && !this.periodInfo.mobile.includes("138100010")) {
				this.$utils.dialog.alert("抱歉，不支持电脑学习，请使用手机完成学习！");
				return false;
			}
			// }
			// 常州开启科目四的限制
			if(cityCode === '320400') {
				const subjectId = !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4;
				if(subjectId === 4 && this.periodInfo.openSubjectFour === 0) {
					this.$utils.dialog.alert("抱歉，实操开班后才可以学习科四！");
					return false;
				}
			}

			// const result = await this.isExistStudy()
			// if (!result) return
			const info = this.getBrowserInfo();
			const type = (info[0].includes('iphone') || info[0].includes('ipad')) ? '苹果' : '安卓'

			// 已学完 不签到 -- 暂时先把广州交信投app嵌入方式的排除
			if (this.checkStudyDone() && this.belongOrganization != '57') {
				this.studyDone = 1;
			}
			sessionStorage.setItem('studyDone', this.studyDone);
			if (this.studyDone == 1) {
				var mobile = this.periodInfo.mobile
				if (sessionStorage.getItem('appno') != 'APP01' || mobile.includes("138100010")) {
					if (this.activeSubject == 2 || this.activeSubject == 3) {
						let courseid = !this.isSupportSubject ? this.periodInfo.otherTypeCourseId : this.activeSubject == 0 || this.activeSubject == 2 ? this.periodInfo.subjectOneCourseId : this.periodInfo.subjectFourCourseId;
						this.$store.dispatch('SetCourseId', courseid)
						this.$router.push({
								path: '/questiontest',
								query: {subject: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4, courseid}
							})
						this.signPic = ''
						return;
					} else {
						let courseid = !this.isSupportSubject ? this.periodInfo.otherTypeCourseId : this.activeSubject == 0 || this.activeSubject == 2 ? this.periodInfo.subjectOneCourseId : this.periodInfo.subjectFourCourseId;
						this.$store.dispatch('SetCourseId', courseid)
						this.$router.push({
							path: '/video',
							query: {subject: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4, courseid}
						})
						this.signPic = ''
						return;
					}
				} else {
					//注意,为了按钮的样式，这里取消跟确定按钮位置调换了
					Dialog.confirm({
						title: '温馨提示',
						theme: 'round-button',
						message: '学时已满，需重置学时才能获取学时 \n（重置后对之前已推送的学时无影响）',
						cancelButtonColor: this.$store.state.app.theme.colorMain,
						confirmButtonColor: '#ee0a24',
						cancelButtonText:'确认',
						confirmButtonText:'取消',
					}).then(() => {
						if (this.activeSubject == 2 || this.activeSubject == 3) {
							let courseid = !this.isSupportSubject ? this.periodInfo.otherTypeCourseId : this.activeSubject == 0 || this.activeSubject == 2 ? this.periodInfo.subjectOneCourseId : this.periodInfo.subjectFourCourseId;
							this.$store.dispatch('SetCourseId', courseid)
							this.$router.push({
									path: '/questiontest',
									query: {subject: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4, courseid}
								})
							this.signPic = ''
							return;
						} else {
							let courseid = !this.isSupportSubject ? this.periodInfo.otherTypeCourseId : this.activeSubject == 0 || this.activeSubject == 2 ? this.periodInfo.subjectOneCourseId : this.periodInfo.subjectFourCourseId;
							this.$store.dispatch('SetCourseId', courseid)
							this.$router.push({
								path: '/video',
								query: {subject: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4, courseid}
							})
							this.signPic = ''
							return;
						}
					}).catch(() => {
						this.confirmReset()
					});
				}
			}
			// 广州交信投执行调起粤学车小程序逻辑
			if (this.belongOrganization == '57' && this.wxMiniProgramCallBack == false) {
				this.openMiniApp();
				return false;
			}
			// 广州交信投校验粤学车小程序授权信息
			if (this.belongOrganization == '57' && this.wxMiniProgramCallBack == true) {
				checkMiniProgramAuthData().then((res) => {
					if (res && res.checkStatus == '0') {
						this.$utils.dialog.alert(res.checkMsg);
						this.wxMiniProgramCallBack = false;
						return false;
					}
				});
			}
			// 无人脸识别照片
			if (!this.signPic) {
				// 先检测有没有正在学的签到
				this.checkSignOut(type)
				return false;
			}
			// 执行签到
			signIn({
				studentId: this.userid,
				belongCategory: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4,
				unitType: type,
				signInIp: window.location.hostname,
				signPic: this.signPic,
				mediaId: this.$store.state.cache.screen.wx ? this.signPic : ''
			}).then((res) => {
				if (res && res.isNewSign == 1) {
					localStorage.clear();
				}
				sessionStorage.setItem('signId', res.basePkId);
				const courseid = !this.isSupportSubject
					? this.periodInfo.otherTypeCourseId : this.activeSubject == 0 || this.activeSubject == 2
						? this.periodInfo.subjectOneCourseId : this.periodInfo.subjectFourCourseId;
				this.$store.dispatch('SetCourseId', courseid)
				this.$router.push({
					path: '/video',
					query: {subject: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4, courseid}
				})
				this.signPic = ''
			}).catch(() => {
				this.signPic = ''
				this.$store.commit('cache/set_face', {
					key: 'signInPhoto',
					value: ''
				})
			})
		},
		// 辽宁东宇再报名
		dongYuReSignUp() {
			Dialog.confirm({
				message: '确认本期课程已结束，可重新报名',
			}).then(() => {
				dongYuReSignUp({
					"basePkId": this.$store.state.user.userid
				}).then((res) => {
					if (res && res.id > 0) {
						this.$dialog.alert({
							title: '提醒',
							message: '重新报名成功，请您重新登录学习！'
						}).then(() => {
							sessionStorage.clear();
							localStorage.clear();
							let keys = document.cookie.match(/[^ =;]+(?=)/g);
							if (keys) {
								for(let i = keys.length; i--;)
									document.cookie = keys[i] + '=0;domain=h5.xunmeizaixian.com;expires=' + new Date(0).toUTCString() + ';path=/';
							}
							window.location.href = "/index.html#/login?appNo=APP02";
						});
					}
				})
			})
		},
		// 山东全省
		reSignUp() {
			Dialog.confirm({
				message: '本期课程已结束，确认后可重新报名',
			}).then(() => {
				reSignUp({
					"basePkId": this.$store.state.user.userid
				}).then((res) => {
					if (res.flag) {
						this.$router.push({path: '/plan-select', query: { appNo: sessionStorage.getItem('appno') }})
					}
				})
			})
		},
		// 云南教练员
		updateCoachInfo(){
			Dialog.confirm({
				message: '本期课程已结束，如已报名新课程请点击确认重新登录，否则请勿确认',
			}).then(() => {
				updateCoachInfo(this.$store.state.user.userid).then(res => {
					if(res == null){
						window.location.href = "/#/yunnanCoach";
					}
				});
			})
		},
		// 乌海
		juHuiReSignUp(){
			Dialog.confirm({
				message: '点击跳转至报名页面',
			}).then(() => {
				window.location.href = "/#/signup/wuhai_push?appNo=APP02";
			})
		},
		check() {
			// this.showRate = true;
			const cityCode = getCityCode();
			const appno = sessionStorage.getItem('appno');
			if (appno === 'APP01') {
				window.location.href = "/renren-fast/app/exp/studyrecord/" + this.userid;
				return;
			}
			if ((cityCode === '210300' && appno === 'APP08')|| appno === 'APP06' || appno === 'APP09' || appno === 'APP10' || (this.periodInfo.belongOrganization != 10 &&this.periodInfo.categoryId == 119)) {
				this.$router.push({
					path: '/studyCertificate/' + this.userid,
					query: { appNo: appno }
				});
			} else {
				this.$router.push({
					path: '/studyCertificateList/' + this.userid,
					query: { appNo: appno }
				});
			}
			// const domain = location.host;
			// const cityCode = getCityCode();
			// if(cityCode === '140700' || cityCode === '150300' || cityCode === '152900') {
			// 	// 晋中各区县/乌海/阿拉善显示合格证列表
			// 	this.qrcodeImgUrl = "/renren-fast/qrcode/getQRCode?content=http%3A%2F%2F" + domain + "%2F%23%2FstudyCertificateList%2F" + this.userid + "%3FappNo%3D" + appno;
			// } else {
			// 	this.qrcodeImgUrl = "/renren-fast/qrcode/getQRCode?content=http%3A%2F%2F" + domain + "%2F%23%2FstudyCertificate%2F" + this.userid + "%3FappNo%3D" + appno;
			// }
			// this.showQrcode = true;
		},
		navToTest() {
			this.$router.push({path: "/test", query: {subject: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4}});
		},
		showCertificatePage() {
			const appno = sessionStorage.getItem('appno');
			const cityCode = getCityCode();
			if(cityCode === '140700' || cityCode === '150300' || cityCode === '152900') {
				// 晋中各区县/乌海/阿拉善显示合格证列表
				this.$router.push({
					path: '/studyCertificateList/' + this.userid,
					query: { appNo: appno }
				});
			} else {
				this.$router.push({
					path: '/studyCertificate/' + this.userid,
					query: { appNo: appno }
				});
			}
			//window.location.href = 'http://'+ window.location.host +'/#/studyCertificate/'+this.userid+'?appNo=APP02';
		},
		doServiceComment() {
			this.serviceRateLoading = true;
			this.serviceRate.studentId = this.userid;
			doServiceComment(this.serviceRate).then(() => {
				this.serviceRateLoading = false;
				this.showRate = false;loading
			}).catch(() => {
				this.serviceRateLoading = false;
				this.showRate = false;
			})
		},
		cancle() {
			this.showRate = false;
			this.showReset = false;
		},
		navBack() {
			if (this.belongOrganization == '45') {
				// 兼容安徽合肥使用uni-app开发的app
				uni.postMessage({
					data: {
						action: 'closeH5'
					}
				})
			} else if (this.belongOrganization == '24' || this.belongOrganization == '57') {
				// 兼容广东交信投使用原生开发的app,app定义closeWindows()方法
				if (this.$store.state.cache.screen.isAndroid) {
					if (!window.Android_JS) {
						this.$utils.dialog.alert("安卓方法Android_JS不存在");
						return;
					}
					if (!window.Android_JS.closeWindows) {
						this.$utils.dialog.alert("安卓方法closeWindows不存在");
						return;
					}
					window.Android_JS.closeWindows();
				}
				if (this.$store.state.cache.screen.isIos) {
					window.webkit.messageHandlers.closeWindows.postMessage(null);
				}
			} else {
				this.$router.push({path: '/auth'})
			}
		},
		openMiniApp() {
			// 测试代码
			// let extraData = "{\"code\": \"A101kuSu/G1l9NS9DkuEuQjQQ7r4tQTz8hgsB5fO/TeRi5dvel+xaH7xHA9eHiE/2IwJx85ODgjjclVPr/NI3Kk7bNh+Yc/31b81otzZ2jqZZHPywnjD8KVznkgP/ZGGzJ3fnT4C6mH9NCVmHrm6hHETDDvgGuulfO11adgzjl906qoXYRf8Q7YDb6cANU2hUNpRCyi/0SxBqH9uFCeOhAxOqX8kqgoBKVUirQlVAak4Bw3I9a15onBTMWpW3HDHchwhBJezgm2uzhnRuABH+6up6Myuo8HP66JxnnJoTl08oXpwKRJGJvw+kl+MdJvm/v0ttk02e37ZT/w+QslAbBdJYqqRBxfc10LV3+9fuU9SHgoLv1FJQzDUb8D8tNs2TvRus/um4h/OCCO0QIovRTPCWEoG559mBif2luOPp0xBf+XswH41zv80Z6o/vGVq/nH5oGaAeUIhOyqtlSZ4qhI/J+90bwiUfCiA9c7fNYRB546nabGS/y4Vd7LukfnGHH2Gl4ZDDTSuy9/GEFNfrUlX9j9Ko+pZGaOjOyvGbDsQTLRNPOwfErtNqLtlbPuYkWrxtPX2XeC3nE8HBcg+R9BeRtAR8+Hua0kOMG02lG6D7aVBpGLIMfoR5Ke8ODa8rhoVCO3H76VtqVuWA5rZgL7nQA7cqW0tZHkALX1eiq1tIQ5=\",\"expirationtime\": \"2024-06-30 11:21:26\",\"stunum\": \"S171014279810596\",\"callback\": \"\"}";
			// saveMiniProgramCallBack(JSON.parse(extraData))
			// 	.then(() => {
			// 		console.log('保存成功');
			// 	})
			// 	.catch(() => {
			// 		this.$utils.dialog.alert('保存授权信息时出错，请联系系统管理员！');
			// 	});
			// 兼容广东交信投使用原生开发的app,app定义closeWindows()方法
			let jsonData = "{\"userName\": \"gh_7a520a05e35a\",\"path\": \"pages/soild/soild-sign-in?responseType=code&from=app&appName=jxedt&t=" + new Date().getTime() + "\"}";
			if (this.$store.state.cache.screen.isAndroid) {
				if (!window.Android_JS) {
					this.$utils.dialog.alert("安卓方法Android_JS不存在");
					return;
				}
				if (!window.Android_JS.openWxMiniProgram) {
					this.$utils.dialog.alert("安卓方法openWxMiniProgram不存在");
					return;
				}
				window.Android_JS.openWxMiniProgram(jsonData);
			}
			if (this.$store.state.cache.screen.isIos) {
				window.webkit.messageHandlers.openWxMiniProgram.postMessage(jsonData);
			}
		},
		getSignRecord(isSingle = false) {
			if (this.$route.query.state === 'single' && !isSingle) return;
			// 特别设置
			// if (location.host.startsWith('shuangpu.')) {
			if (sessionStorage.getItem('appno') === 'APP02' || sessionStorage.getItem('appno') === 'APP04') {
				this.showStudyLog = false;
				return;
			}
			this.loading = true;
			getSignRecord({
				page: this.currentPage,
				limit: this.pageSize,
				studentId: this.userid,
				belongCategory: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2  ? 1 : 4
			}).then((res) => {
				const list = res.list;
				this.loading = false;
				this.finished = true;
				if (!list.length) {
					return;
				}
				this.currentPage++;
				this.studyList = this.studyList.concat(res.list);
			}).catch(() => {
				this.loading = false;
				this.finished = true;
				// this.$message.error(err);
			});
		},
		getPeriodInfo() {
			getPeriodInfo(this.userid).then(res => {
				this.periodInfo = res;
				const {categoryId, categoryName, isCommentService, studentStatus} = res;
				this.planName = res.planName;
				this.$store.dispatch("SetCategory", {categoryId, categoryName})
				const cityCode = getCityCode();
				this.belongOrganization = this.periodInfo.belongOrganization;
				this.studentPayStatus = this.periodInfo.studentPayStatus;
				if (this.belongOrganization == '10') {
					this.ifshowLogInfo = true;
				}
				if (this.belongOrganization == '24' || this.belongOrganization == '45' || this.belongOrganization == '57') {
					this.showNavBack = true;
				}
				if (this.belongOrganization == '25' || this.belongOrganization == '37' || this.belongOrganization == '50' || this.belongOrganization == '57') {
					this.showPeriodViewBtn = true;
				}
				if (this.belongOrganization != '13' && this.belongOrganization != '24' && this.belongOrganization != '38' && this.belongOrganization != '41' && this.belongOrganization != '45' && this.belongOrganization != '57') {
					this.logoutDisplay = 'display:block';
				}
				this.resetFlag = res.ifReset1;
				sessionStorage.setItem("cameraType", this.periodInfo.cameraType);
				if (isCommentService == 0 && studentStatus == 2) {
					this.showRate = true;
				}
				if (getEnterpriseId() == 170 && studentStatus == 2) {
					this.istips = true;
				}
				if (this.periodInfo.testStudyFlag == '1') {
					this.subjects = [{
							label: "科一视频",
							value: 1,
						}, {
							label: "科四视频",
							value: 4,
						}, {
							label: "科一习题",
							value: 1,
						}, {
							label: "科四习题",
							value: 4,
						},
					]
				}
				// 测试(考试)按钮
				if (studentStatus == 2) {
					if (this.belongOrganization === '13') {
						this.testTitle = '进入测试';
					}
					this.isTested = this.periodInfo.mockTestFlag === '1';
				}

				// 合格证明按钮
				if (this.periodInfo.isQualified == 1 && studentStatus == 2 && res.categoryId > 115) {
					this.isQualified = true;
				}
				if (this.belongOrganization == 38) {
					this.isQualified = false;
				}

				// 山西双普大同不考试，不能自己下载合格证
				if (cityCode === '140200') {
					this.isTested = false;
					this.isQualified = false;
				}

				// 云南始终出现考试链接
				if (cityCode.substring(0, 2) === '53') {
					this.isTested = false;
					this.isTestedYN = true;
					this.isQualified = false;
				}

				// 经过人脸识别后返回
				if (this.signPic) {
					this.start();
				}

				// 特别设置-从业人员继续教育类
				if (sessionStorage.getItem('appno') === 'APP02') {
				// if (location.host.startsWith('shuangpu.')) {
					// 累计完成学时
					let otherTypeValidTimeSP = this.periodInfo.otherTypeValidTime;
					if(otherTypeValidTimeSP) {
						if(otherTypeValidTimeSP > this.periodInfo.otherTypeTotalTime) {
							this.otherTypeValidTimeSP = parseInt(this.periodInfo.otherTypeTotalTime) / 45;
						} else {
							this.otherTypeValidTimeSP = (parseFloat(otherTypeValidTimeSP) / 45).toFixed(1);
						}
					} else {
						this.otherTypeValidTimeSP = '0';
					}
					// 目标学时
					let otherTypeTotalTimeSP = this.periodInfo.otherTypeTotalTime;
					if(otherTypeTotalTimeSP) {
						this.otherTypeTotalTimeSP = parseInt(otherTypeTotalTimeSP) / 45;
					}
					// 已学学时
					let otherTypeStudyTimeSP = this.periodInfo.otherTypeStudyTime;
					if(otherTypeStudyTimeSP && otherTypeTotalTimeSP) {
						if(otherTypeStudyTimeSP > this.periodInfo.otherTypeTotalTime) {
							this.otherTypeStudyTimeSP = 100;
						} else {
							this.otherTypeStudyTimeSP = (((parseFloat(otherTypeStudyTimeSP) / 45) / parseFloat(this.otherTypeTotalTimeSP)) * 100).toFixed(1);
						}
					}
					if(!otherTypeStudyTimeSP) {
						this.otherTypeStudyTimeSP = '0';
					}

					this.showLogout = true;
					this.timeUnit = false;
				}
				var mobile = this.periodInfo.mobile
				if(sessionStorage.getItem('appno') != 'APP01' || mobile.includes("138100010")){
					this.otherReset = false;
				}
				// 特别设置-江苏常州初学类
				if(cityCode === '320400' && sessionStorage.getItem('appno') === 'APP01') {
					// this.isReset = true;
					this.showHasLearned = true;
					// 检查是否已提交电子签名
					if(this.periodInfo.isSubmitSign == 0) {
						this.$dialog.alert({
							title: '提醒',
							message: '您还没有提交合同电子签名，\r\n请去手写签名并提交！',
						}).then(() => {
							this.$router.push({path: "/auth", query: { step: 3, sign: 1 }});
						});
					}
				}

				// 特别设置-江苏常州教练员继续教育+学员已学完
				if(cityCode === '320400' && sessionStorage.getItem('appno') === 'APP02' && studentStatus === '2' && categoryId === 119) {
					// 查询是否已有新年度培训配置
					getNewCertificate(this.$store.state.user.token).then(res => {
						if(res.new === 1) {
							this.$dialog.confirm({
								title: '温馨提示',
								message: '当前已有新年度课程培训计划已启动，请您确认报名并支付！',
							}).then(() => {
								// 初始化学员状态
								initCzStudent({token: this.$store.state.user.token}).then(() => {
									sessionStorage.setItem("czjxjy", '1');
									this.$router.push({path: "/auth", query: { step: 1 }});
								});
							}).catch(() => {
								this.$dialog.alert({message: "您已取消报名新年度培训计划，如需报名请重新打开该页面"});
							});
						}
					});
				}

				// 特别设置-企业安全培训
				if(sessionStorage.getItem('appno') === 'APP03') {
					// 检查是否已提交电子签名
					if(this.periodInfo.isSubmitSign === 0) {
						this.$dialog.alert({
							title: '提醒',
							message: '您还没有提交本人电子签名，\r\n请去手写签名并提交！',
						}).then(() => {
							this.$router.push({path: "/electronic-sign", query: { step: 3, sign: 1 }});
						});
					}
				}
				this.showStudentInfo = true;
				this.xmLoadingShow = false;
			})
		},
		resetPeriod() {
			this.showReset = true;
		},

		showCurrentLogInfo(basePkId) {
			Vue.use(Toast);
			showLogInfo({
				basePkId: basePkId,
			}).then((data) => {
				console.log(data);
				if (data == null) {
					Toast({
						message: "未获取到此条学习记录，请至少看完一个完整视频后再进行签退！！！",
						position: 'bottom',
						duration: 3000
					});
				} else {
					this.duration = data.duration == ""? data.submitDuration : data.duration
					this.deductionduration = data.deductionduration == ""? "0" : data.deductionduration
					this.deductionreson = data.deductionreson == ""? "无扣除" : data.deductionreson
					Toast({
						message: "提交学时：" + data.submitDuration + "分钟"
								+ "\n有效时长：" + this.duration  + "分钟"
								+ "\n"+"扣除时长：" + this.deductionduration  + "分钟"
								+ "\n"+"扣除原因：" + this.deductionreson,
						position: 'bottom',
						duration: 3000
					});
				}
			})
		},
		confirmReset() {
			resetPeriodInfo({
				studentId: this.userid,
				subject: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4,
			}).then(() => {
				localStorage.clear();
				this.showReset = false;
				this.getPeriodInfo();
				this.currentPage = 1;
				this.pageSize = 10;
				this.studyList = [];
				this.finished = false;
				this.getSignRecord(true);
			})
			.catch(() => {
				// this.$message.error(err);
			});
		},
		logout() {
			// 清除缓存
			sessionStorage.clear();
			localStorage.clear();
			let keys = document.cookie.match(/[^ =;]+(?=)/g);
			if(keys) {
				for(let i = keys.length; i--;)
					document.cookie = keys[i] + '=0;domain=h5.xunmeizaixian.com;expires=' + new Date(0).toUTCString() + ';path=/';
			}
			// window.location.href = "/index.html#/login?appNo=" + sessionStorage.getItem('appno');
			this.$router.push({ path: '/study-center'})
		},
		changePlan() {
			this.$router.push({path: '/plan-select', query: { appNo: sessionStorage.getItem('appno') }})
		},
		thechangePlan(){
			Dialog.confirm({
						title: '温馨提示',
						theme: 'round-button',
						message: '请确认本课程已学习完毕，切换课程后学时将会重置，以免延误学习进度',
						cancelButtonColor: this.$store.state.app.theme.colorMain,
						confirmButtonColor: '#ee0a24',
						cancelButtonText:'确认',
						confirmButtonText:'取消',
					}).then(() => {

					}).catch(() => {
						this.$api.changePlan(this.userid).then((res) => {
							if(res.code == 0){location.reload()}
							})
					});

		},
		tool(dian) {
			this.dian = dian+1
			if (this.dian >= 5) {
				new Vconsole();
			}
		},
		checkRecovery() {
			recoveryCheckStatus(this.userid).then(res => {
				if(res.recoveryStatus === 1) {
					this.showRecovery = true;
				}
			});
		},
		confirmRecovery() {
			this.loadingRecovery = true;
			recoveryProc(this.userid).then(() => {
				this.loadingRecovery = false;
				this.showRecovery = false;
				this.$dialog.alert({
					title: '提醒',
					message: '学习记录还原成功！'
				}).then(() => {
					window.location.reload();
				});
			});
		},
		cancelRecovery() {
			this.showRecovery = false;
		},
		isPcWeixinBrowser() {
			let system = {
				win: false,
				mac: false,
				xll: false,
				ipad: false
			};

			let p = navigator.platform;
			system.win = p.indexOf("Win") === 0;
			system.mac = p.indexOf("Mac") === 0;
			system.x11 = (p === "X11") || (p.indexOf("Linux") === 0);
			system.ipad = (navigator.userAgent.match(/iPad/i) !== null) ? true : false;

			if (system.win || system.mac || system.xll || system.ipad) {
				return true;
			} else {
				let ua = navigator.userAgent.toLowerCase();
				return ua.match(/MicroMessenger/i) != "micromessenger";
			}
		},
		periodView() {
			if (this.belongOrganization == '57') {
				this.$router.push({
					path: '/periodGD',
					query: {
						subject: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4
					}
				})
			} else {
				this.$router.push({
					path: '/period',
					query: {
						subject: !this.isSupportSubject ? 0 : this.activeSubject == 0 || this.activeSubject == 2 ? 1 : 4
					}
				})
			}
		}
	}
};
</script>

<style lang="less" scoped>
	.logout-sp {
		position: absolute;
		right: 20px;
		top: 24px;
	}
	.logout-sp a {
		color: #ffffff;
	}
	.change-xm {
		position: absolute;
		left: 20px;
		top: 24px;
	}
	.change-xm a {
		color: #ffffff;
	}
	.imp-check {
		color: #ee0a24;
		font-weight: bold;
	}
	.header {
		position: absolute;
		width: 100%;
		height: 200px;
		padding-bottom: 14px;
		border-bottom-left-radius: 26px 10px;
		border-bottom-right-radius: 26px 10px;
	}

	.page-container {
		min-height: 100vh;
		background: #f5f5f5;
	}

	.page-body {
		margin-top: 0;
		padding: 100px 15px 15px;

		.content {
			position: relative;
			top: -14px;
			background: #fff;
			border-radius: 14px;
			padding: 15px;

			.content-wrap {
				.section-top {
					padding: 26px 15px 60px;
					box-shadow: 0px 2px 8px 1px #e1e0df;
					border-radius: 14px;

					.title {
						position: relative;
						font-size: 17px;
						text-align: center;

						.txt {
							position: relative;
						}

						.line {
							position: absolute;
							top: 50%;
							display: inline-block;
							width: 30px;
							height: 2px;
							background: #fca142;
							margin: -1px 0 0;

							&:nth-of-type(1) {
								left: -42px;
							}

							&:nth-of-type(2) {
								right: -42px;
							}
						}
					}

					.para {
						line-height: 1.8;
					}

					.extra {
						margin-top: 40px;

						.extra-title {
							position: relative;
							font-weight: bold;

							&.has-line {
								padding-left: 10px;
							}

							&::before {
								position: absolute;
								content: "";
								width: 4px;
								height: 14px;
								background: linear-gradient(90deg, #fca142, #ff8506);
								border-radius: 4px;
								left: 0;
								top: 50%;
								margin-top: -6px;
							}
						}

						.extra-content {
							margin-top: 15px;

							.line {
								position: relative;
								line-height: 1.8;
								display: flex;
								align-items: baseline;

								.idx {
									display: inline-block;
									width: 14px;
									height: 14px;
									background: #fca142;
									border-radius: 14px;
									margin-right: 8px;
									flex: 0 0 14px;
									text-align: center;
									line-height: 14px;
									color: #fff;
									font-size: 12px;
								}
							}
						}
					}
				}

				.section-bottom {
					margin-top: 40px;
				}
			}

			.info {
				display: flex;
				justify-content: space-evenly;
				align-items: center;

				.info-item {
					text-align: center;
					font-size: 15px;

					.top {
						margin-bottom: 8px;
						font-size: 18px;
					}

					.bottom {
						color: #666;
						font-size: 14px;
					}
				}
			}

			.btn-wrap {
				margin-top: 40px;

				.a-btn {
					margin-top: 20px;
					display: flex;
					justify-content: center;

					a {
						text-decoration: underline;

						& + a {
							margin-left: 20px;
						}
					}
				}
			}
		}

		.record {
			padding: 15px;
			background: #ffffff;
			border-radius: 14px;

			.table {
				margin-top: 24px;
				max-height: 300px;
				overflow-y: auto;

				.table-list {
					display: flex;
					align-items: center;

					& + .table-list {
						margin-top: 8px;
					}

					.item {
						font-size: 12px;
						flex: 0 0 30%;
						text-align: center;

						&:first-of-type {
							flex: 0 0 40%;
							text-align: left;
						}

						&.grey {
							color: #999;
						}

						&.red {
							color: #ff300e;
						}

						&.orange {
							color: #ff8506;
						}
					}

					&.hd {
						.item {
							font-size: 16px;
						}
					}
				}
			}

			.section-top {
				display: flex;

				.left {
					margin-right: 10px;
					span {
						color: #666;
					}
				}

				.right {
					flex: 0 0 76px;
					display: flex;
					flex-direction: column;
					justify-content: center;

					img {
						width: 100%;
						height: 76px;
						object-fit: cover;
						border-radius: 76px;
					}
				}
			}

			.section-content {
				padding: 20px 10px 0 10px;
				font-size: 15px;

				.list-item {
					line-height: 1.6;
					margin-bottom: 4px;

					&.tips {
						margin-top: 40px;
						font-size: 13px;
					}
				}

			}
		}
	}

	// .van-popup {
	//   background: transparent;
	// }

	.van-popup .van-popover__arrow {
		background: #fca142;
	}

	.van-popover__content {
		background: #fca142;
		color: #fff;
	}

	.van-circle {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.circle-wrap {
		position: relative;
		height: 200px;
		.finish{
			position: absolute;
			top: 50%;
			left: 20%;
			height: 160px;
			width: 100%; /* 或者你想要的宽度 */
			margin: -80px 0 0 -80px;
			background-image: url(~@/assets/finish.png);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
		.unfinish{
			position: absolute;
			top: 50%;
			left: 20%;
			height: 160px;
			width: 100%; /* 或者你想要的宽度 */
			margin: -80px 0 0 -80px;
			background-image: url(~@/assets/unfinish.png);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
		.circle {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 160px;
			height: 160px;
			margin: -80px 0 0 -80px;
			background-image: url(~@/assets/icon-circle-sm.png);
			background-size: cover;
			.circle-inner {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;

				.title {
					margin: 0 0 4px 0;
					color: #ff8506;
					font-size: 20px;
				}

				span {
					color: #999;
					font-size: 11px;
				}
			}

		}
	}

	.popup-reset {
		width: 290px;
		padding: 20px;

		.content {
			line-height: 1.8;
			margin-bottom: 20px;
		}

		.btn-wrap {
			display: flex;
			justify-content: space-evenly;
			width: 100%;

			.btn {
				width: 40%;
			}
		}
	}

	.popup-content {
		width: 290px;
		// border-radius: 12px;
		overflow: hidden;
		background: #fff;

		& > .title {
			background: linear-gradient(90deg, #fca142, #ff8506);
			padding: 15px;
			font-size: 18px;
			color: #fff;
			text-align: center;
		}

		.content {
			margin: 20px 0 10px;
			padding: 0 10px;
			font-size: 12px;

			.list {
				display: flex;
				align-items: center;
				margin-top: 10px;

				span {
					display: inline-block;
					width: 50%;
				}
			}
		}

		.btn-wrap {
			display: flex;
			padding: 15px;

			.btn {
				width: 40%;
				font-weight: 500;
			}
		}
	}

	.APP02 {
		@colorMain: #2db1ac;
		@colorSecond: #48d8a0;

		.circle-wrap {
			.circle {
				.circle-inner {
					.title {
						color: @colorMain;
					}
				}

			}
		}
	}

	.APP03 {
		@colorMain: #1175fe;
		@colorSecond: #3fadfa;

		.circle-wrap {
			.circle {
				.circle-inner {
					.title {
						color: @colorMain;
					}
				}

			}
		}
	}

	.share-tips {
		width: 100%;
		height: 100%;
		text-align: center;
		line-height: 22px;
	}
	.share-tips img {
		width: 120px;
	}
	.share-tips span {
		color: #ee0a24;
	}
	.cx-picker .van-cell {
		padding: 0px 10px;
	}
</style>
<style lang="less">
	.circle.zero {
		.van-circle {
			svg {
				display: none;
			}
		}
	}
</style>
